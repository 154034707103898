<template>
    <div>
        <v-overlay :value="overlay" z-index="999999">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-toolbar dark color="primary" dense>
        <v-radio-group v-model="action" row hide-details>
            <v-radio value="ROLE_TYPE">
            <template v-slot:label>
                <b class="white--text">TIPO DE ROLES</b></template
            >
            </v-radio>
            <v-radio value="PARTY_GROUP">
            <template v-slot:label> <b class="white--text">EQUIPOS</b></template>
            </v-radio>
            <v-radio value="PARTY_ROLE">
            <template v-slot:label>
                <b class="white--text">ASIGNACIÓN DE ROLES</b></template
            >
            </v-radio>
            <v-radio value="SERVICE">
            <template v-slot:label>
                <b class="white--text">SERVICIOS</b></template
            >
            </v-radio>
        </v-radio-group>
        </v-toolbar>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            class="elevation-1"
            :loading="loadingTable"
            :search="search"
            item-key="party_id"
            loading-text="Buscando registros..."
            dense
        >
            <template v-slot:top>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="3" class="text-center">
                        <v-toolbar-title>
                        {{ titleDataTable }}
                        </v-toolbar-title>
                    </v-col>
                    <v-col :md="action == 'SERVICE' ? 3 : 6">
                        <v-text-field v-model="search" label="Buscar" />
                    </v-col>
                    <v-col md="3" v-if="action == 'SERVICE'">
                        <v-autocomplete
                        v-model="state"
                        :items="states"
                        dense
                        item-value="state"
                        item-text="name"
                        hide-details="auto"
                        @change="getServiciosCrm"
                        />
                    </v-col>
                    <v-col md="3" class="text-center">
                        <v-btn color="success" dark small @click="dialogAction">
                        <v-icon>mdi-plus</v-icon> Nuevo
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-results="{ item }">
                No se encontraron compras
            </template>
            <template v-slot:no-data>
                Sin registros
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="warning"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="editAction(item)"
                        >
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Editar configuración</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="!['PARTY_ROLE', 'ROLE_TYPE','PARTY_GROUP'].includes(action) && item.thru_date == null"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="error"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteServicio(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Eliminar configuración</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="['PARTY_GROUP'].includes(action)"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :color="item.status_id == 'PARTY_ENABLED' ? 'error' : 'success'"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="updateEstadoEquipo(item)"
                        >
                            mdi-{{item.status_id == 'PARTY_ENABLED' ? 'delete' : 'check' }} 
                        </v-icon>
                    </template>
                    <span>{{item.status_id == 'PARTY_ENABLED' ? 'Deshabilitar' : 'Habilitar' }} Equipo</span>
                </v-tooltip>
                <v-tooltip bottom v-if="action == 'SERVICE' && item.thru_date != null">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="success"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="activarServicio(item)"
                        >
                            mdi-reload
                        </v-icon>
                    </template>
                    <span>Activar configuración</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-dialog v-model="dialogTipoRol" persistent max-width="600px">
            <v-card>
                <v-overlay :value="overlay" absolute>
                <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
                <v-form ref="form_assign_work_effort">
                <v-card-title>{{ typeAction }} tipo de Rol</v-card-title>
                <v-col cols="12">
                    <v-text-field
                    v-model="editedItemTipoRol.role_type_id"
                    label="Identificador del tipo de rol"
                    dense
                    class="text-center pb-2"
                    :rules="required"
                    counter="20"
                    v-mask="'XXXXXXXXXXXXXXXXXXXX'"
                    @keyup="
                        editedItemTipoRol.role_type_id = editedItemTipoRol.role_type_id.toUpperCase()
                    "
                    />
                    <v-row>
                    <v-col md="6">
                        <v-text-field
                        v-model="editedItemTipoRol.description"
                        type="text"
                        label="Nombre del Rol"
                        dense
                        class="text-center pb-2"
                        :rules="required"
                        />
                    </v-col>
                    <v-col md="6">
                        <v-autocomplete
                        v-model="editedItemTipoRol.parent_type_id"
                        label="Rol padre"
                        :items="parentRoleType"
                        dense
                        item-value="role_type_id"
                        item-text="description"
                        clearable
                        />
                    </v-col>
                    </v-row>
                    <em
                    >Nota: si no se coloca un tipo de Rol padre este se convertirá en
                    un tipo de Rol padre</em
                    >
                </v-col>
                <v-col>
                    <v-row>
                    <v-col class="text-center">
                        <v-btn color="primary" @click="storeTipoRol">
                        <v-icon>mdi-content-save</v-icon> GUARDAR
                        </v-btn>
                        <v-btn color="default" @click="close">
                        <v-icon>mdi-cancel</v-icon> Cerrar
                        </v-btn>
                    </v-col>
                    </v-row>
                </v-col>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogGestionRoles" persistent max-width="600px">
            <v-toolbar dark color="primary" dense>
                <div dense class="check-dias">
                <div>
                    <v-icon>mdi-medical-bag</v-icon> Gestionar roles
                    {{ person == "" ? "" : "de  " + person }}
                </div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="blue-grey lighten-2"
                        v-bind="attrs"
                        v-on="on"
                        fab
                        @click="addTipoRol"
                        x-small
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    </template>
                    <span>Agregar rol</span>
                </v-tooltip>
                </div>
            </v-toolbar>
            <v-card>
                <v-col cols="12" class="py-0">
                <v-form ref="form_horario">
                    <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                        <v-card class="px-3 py-3">
                            <v-data-table
                            :headers="headersAsignacionRol"
                            :items="dataTableAsignacionRol"
                            class="elevation-1"
                            :loading="loadingTable"
                            item-key="uuid"
                            loading-text="Buscando roles..."
                            dense
                            >
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                @click="deleteAsignacionRol(item)"
                                color="error"
                                >
                                mdi-delete
                                </v-icon>
                            </template>
                            <template v-slot:item.description="props">
                                <v-edit-dialog
                                :return-value.sync="props.item.role_type_id"
                                large
                                persistent
                                save-text="Guardar"
                                cancel-text="Cancelar"
                                @save="storeAsginacionRol(props)"
                                >
                                <div>{{ props.item.description }}</div>
                                <template v-slot:input>
                                    <div class="mt-4 text-h6">Rol</div>
                                    <v-autocomplete
                                    v-model="props.item.role_type_id"
                                    :items="roleTypesId"
                                    dense
                                    outlined
                                    item-value="role_type_id"
                                    item-text="description"
                                    :rules="required"
                                    hide-details="auto"
                                    prepend-inner-icon="mdi-format-list-bulleted"
                                    />
                                </template>
                                </v-edit-dialog>
                            </template>
                            </v-data-table>
                            <v-card-actions>
                            <v-col class="text-center">
                                <v-btn @click="close">
                                <v-icon>mdi-cancel</v-icon> Cancelar
                                </v-btn>
                            </v-col>
                            </v-card-actions>
                        </v-card>
                        </v-col>
                    </v-row>
                    </v-col>
                </v-form>
                </v-col>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogServicio" persistent max-width="700px">
            <v-toolbar dark color="primary" dense>
                <div dense class="check-dias">
                <div><v-icon>mdi-account-star</v-icon> Gestionar servicio</div>
                </div>
            </v-toolbar>
            <v-card>
                <v-col cols="12" class="py-0">
                <v-col cols="12">
                    <v-row>
                    <v-col cols="12">
                        <v-card class="px-3 py-3">
                        <v-form ref="form_servicio">
                            <v-row class="mt-3">
                            <v-col cols="12" md="6">
                                <v-text-field
                                v-model="editedItemServicio.nombre"
                                label="Nombre"
                                dense
                                class="text-center pb-2"
                                :rules="required"
                                />
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                v-model="editedItemServicio.tiempo_antes"
                                type="number"
                                label="Tiempo antes (min)"
                                dense
                                class="text-center pb-2"
                                :rules="required"
                                @keyup="
                                    editedItemServicio.tiempo_antes =
                                    editedItemServicio.tiempo_antes < 0
                                        ? 0
                                        : editedItemServicio.tiempo_antes
                                "
                                />
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                v-model="editedItemServicio.tiempo_despues"
                                type="number"
                                label="Tiempo despues (min)"
                                dense
                                class="text-center pb-2"
                                :rules="required"
                                @keyup="
                                    editedItemServicio.tiempo_despues =
                                    editedItemServicio.tiempo_despues < 0
                                        ? 0
                                        : editedItemServicio.tiempo_despues
                                "
                                />
                            </v-col>
                            <v-col cols="12" md="6" class="mb-2">
                                <v-autocomplete
                                v-model="editedItemServicio.work_effort_type_id"
                                :items="workEffortTypes"
                                dense
                                label="Tipo de evento"
                                item-value="work_effort_type_id"
                                item-text="description"
                                :rules="required"
                                hide-details="auto"
                                />
                            </v-col>
                            <v-col cols="12" md="6" class="mb-2">
                                <v-autocomplete
                                v-model="editedItemServicio.product_store_id"
                                :items="productStrores"
                                dense
                                label="Tienda"
                                item-value="product_store_id"
                                item-text="store_name"
                                :rules="required"
                                hide-details="auto"
                                />
                            </v-col>
                            </v-row>
                            <v-toolbar dark color="primary" dense class="mt-3" rounded>
                            <div dense class="check-dias">
                                <div>
                                <v-icon>mdi-account-star</v-icon> Sub servicio
                                </div>
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    color="blue-grey lighten-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    fab
                                    x-small
                                    @click="addSubServicio"
                                    >
                                    <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Agregar sub servicio</span>
                                </v-tooltip>
                            </div>
                            </v-toolbar>
                            <v-data-table
                                :headers="headersSubServicio"
                                :items="editedItemServicio.sub_servicio"
                                class="elevation-1"
                                :loading="loadingTable"
                                item-key="uuid"
                                loading-text="Buscando sub servicios..."
                                dense
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-icon @click="borrarSubServicio(item)" color="error">
                                    mdi-delete
                                    </v-icon>
                                </template>
                                <template v-slot:item.internal_name="props">
                                    <v-edit-dialog
                                        :return-value.sync="props.item.product_id"
                                        large
                                        persistent
                                        save-text="Asignar"
                                        cancel-text="Cancelar"
                                        @save="asginacionSubServicio(props)"
                                    >
                                        <div>{{ props.item.internal_name }}</div>
                                        <template v-slot:input>
                                            <div class="mt-4 text-h6">Productos</div>
                                            <v-autocomplete
                                            v-model="props.item.product_id"
                                            :items="products"
                                            dense
                                            outlined
                                            item-value="product_id"
                                            item-text="internal_name"
                                            :rules="required"
                                            hide-details="auto"
                                            prepend-inner-icon="mdi-format-list-bulleted"
                                            />
                                        </template>
                                    </v-edit-dialog>
                                </template>
                                <template v-slot:item.duracion="{ item }">
                                    <v-text-field
                                        v-model.number="item.duracion"
                                        min="0"
                                        type="number"
                                        dense
                                        hide-details
                                        @keyup="item.duracion =  item.duracion < 0 ? 0 : item.duracion"
                                    />
                                </template>
                                <template v-slot:item.valoracion="{ item }">
                                    <v-checkbox 
                                        v-model="item.valoracion" 
                                        hide-details
                                        class="mt-0"
                                    />
                                </template>
                            </v-data-table>
                            <v-toolbar dark color="primary" dense class="mt-3" rounded>
                                <div dense class="check-dias">
                                    <div>
                                        <v-icon>mdi-account-star</v-icon> Recursos para el servicio
                                    </div>
                                    <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        color="blue-grey lighten-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        fab
                                        x-small
                                        @click="addRecursoServicio"
                                        >
                                        <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Agregar recurso</span>
                                    </v-tooltip>
                                </div>
                            </v-toolbar>
                            <v-data-table
                                :headers="headersRecursoServicio"
                                :items="editedItemServicio.recursos"
                                class="elevation-1"
                                :loading="loadingTable"
                                item-key="uuid"
                                loading-text="Buscando recursos..."
                                dense
                            >
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                @click="deleteRecursoServicio(item)"
                                color="error"
                                >
                                mdi-delete
                                </v-icon>
                            </template>
                            <template v-slot:item.description="props">
                                <v-edit-dialog
                                    :return-value.sync="props.item.role_type_id"
                                    large
                                    persistent
                                    save-text="Asignar"
                                    cancel-text="Cancelar"
                                    @save="asginacionRecurso(props)"
                                >
                                <div>{{ props.item.description }}</div>
                                <template v-slot:input>
                                    <div class="mt-4 text-h6">Recurso</div>
                                    <v-autocomplete
                                    v-model="props.item.role_type_id"
                                    :items="roleTypesId"
                                    dense
                                    outlined
                                    item-value="role_type_id"
                                    item-text="description"
                                    :rules="required"
                                    hide-details="auto"
                                    prepend-inner-icon="mdi-format-list-bulleted"
                                    />
                                </template>
                                </v-edit-dialog>
                            </template>
                            </v-data-table>
                            <v-col cols="12">
                            <v-row>
                                <v-col class="text-center">
                                <v-btn color="primary" @click="storeServicio">
                                    <v-icon>mdi-content-save</v-icon> GUARDAR
                                </v-btn>
                                <v-btn color="default" @click="close">
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                                </v-col>
                            </v-row>
                            </v-col>
                        </v-form>
                        </v-card>
                    </v-col>
                    </v-row>
                </v-col>
                </v-col>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEquipo" persistent max-width="600px">
            <v-card>
                <v-overlay :value="overlay" absolute>
                <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
                <v-form ref="form_equipo">
                <v-card-title>{{ typeAction }} equipo de trabajo</v-card-title>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="6">                    
                            <v-text-field
                                v-model="editedItemEquipo.group_name"
                                type="text"
                                label="Nombre del equipo"
                                dense
                                class="text-center pb-2"
                                :rules="required"
                            />                                          
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0">                    
                            <v-autocomplete
                                v-model="editedItemEquipo.product_store_id"
                                :items="productStrores"
                                denss                                
                                label="Tienda del equipo"
                                item-value="product_store_id"
                                item-text="store_name"
                                :rules="required"
                                hide-details="auto"                              
                            />                                    
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-toolbar dark color="primary" dense class="mt-3" rounded>
                        <div dense class="check-dias">
                            <div>
                                <v-icon>mdi-account-star</v-icon> Roles del equipo
                            </div>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="blue-grey lighten-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        fab
                                        x-small
                                        @click="addRolesEquipo"
                                    >
                                    <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Agregar rol</span>
                            </v-tooltip>
                        </div>
                    </v-toolbar>
                </v-col>
                <v-col cols="12">
                    <v-data-table
                        :headers="headersRolesEquipo"
                        :items="editedItemEquipo.roles"
                        class="elevation-1"
                        :loading="loadingTable"
                        item-key="uuid"
                        loading-text="Buscando roles..."
                        dense
                    >
                        <template v-slot:item.actions="{ item }">
                            <v-icon
                                @click="deleteRolEquipo(item)"
                                color="error"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:item.description="props">
                            <v-edit-dialog
                                :return-value.sync="props.item.role_type_id"
                                large
                                persistent
                                save-text="Asignar"
                                cancel-text="Cancelar"
                                @save="asginacionRolEquipo(props)"
                            >
                                <div>{{ props.item.description }}</div>
                                <template v-slot:input>
                                    <div class="mt-4 text-h6">ROL</div>
                                    <v-autocomplete
                                        v-model="props.item.role_type_id"
                                        :items="roleTypesId"
                                        dense
                                        outlined
                                        item-value="role_type_id"
                                        item-text="description"
                                        :rules="required"
                                        hide-details="auto"
                                        prepend-inner-icon="mdi-format-list-bulleted"
                                    />
                                </template>
                            </v-edit-dialog>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col>
                    <v-row>
                        <v-col class="text-center">
                            <v-btn color="primary" @click="storeEquipo">
                            <v-icon>mdi-content-save</v-icon> GUARDAR
                            </v-btn>
                            <v-btn color="default" @click="close">
                            <v-icon>mdi-cancel</v-icon> Cerrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from "vue"

    import { mapActions, mapState, mapMutations } from "vuex"

    var textRol = "Clic aquí para seleccionar el rol"
    var textSubServicio = "Clic aquí para asignar el producto"
   
    export default {
        name: "HorarioTrabajoComponent",
        data: () => ({
            headersRoleType: [
                { text: "ID", value: "role_type_id" },
                { text: "Descripción", value: "description" },
                { text: "Padre", value: "parent_description" },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false,
                    width: "120px",
                    align: "center",
                },
            ],
            headersPartyRole: [
                { text: "ID", value: "party_id" },
                { text: "Persona", value: "name" },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false,
                    width: "120px",
                    align: "center",
                },
            ],
            headersService: [
                { text: "ID", value: "servicio_crm_id" },
                { text: "Nombre", value: "nombre" },
                { text: "Tiempo antes", value: "tiempo_antes" },
                { text: "Tiempo despues", value: "tiempo_despues" },
                { text: "Tienda", value: "store_name" },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false,
                    width: "120px",
                    align: "center",
                },
            ],
            headersRecursoServicio: [
                { text: "ID", value: "role_type_id" },
                { text: "Nombre", value: "description" },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false,
                    width: "120px",
                    align: "center",
                },
            ],
            headersSubServicio: [
                { text: "ID", value: "product_id", width: "100px" },
                { text: "Producto", value: "internal_name",width: "140px", },
                { text: "Duracion (min)", value: "duracion" , width: "100px"},
                { text: "Valoración", value: "valoracion",  align: "center" },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false,
                    width: "100px",
                    align: "center",
                },
            ],
            headersAsignacionRol: [
                { text: "ID", value: "role_type_id" },
                { text: "Descripción", value: "description" },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false,
                    width: "120px",
                    align: "center",
                },
            ],
            headersRolesEquipo:[
                { text: "ID", value: "role_type_id" },
                { text: "Nombre", value: "description" },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false,
                    width: "120px",
                    align: "center",
                },
            ],
            headersPartyGroup: [
                { text: "ID", value: "party_id" },
                { text: "Equipo", value: "group_name" },
                { text: "Tienda", value: "store_name" },
                { text: "Acciones",value: "actions", sortable: false, width: "120px", align: "center" },
            ],
            required: [(v) => !!v || "El campo es requerido"],
            states: [
                { state: true, name: "Activo" },
                { state: false, name: "Inactivo" },
            ],
            editedItemTipoRol: {
                role_type_id: null,
                parent_type_id: null,
                description: null,
            },
            defaultEditedItemTipoRol: {
                role_type_id: null,
                parent_type_id: null,
                description: null,
            },
            editedItemServicio: {
                servicio_crm_id: null,
                product_store_id: null,
                work_effort_type_id: null,
                nombre: null,
                tiempo_antes: "0",
                tiempo_despues: "0",
                recursos: [],
                sub_servicio: [],
            },
            defaultEditedItemServicio: {
                servicio_crm_id: null,
                product_store_id: null,
                work_effort_type_id: null,
                nombre: null,
                duracion: null,
                recursos: [],
                sub_servicio: [],
            },
            editedItemEquipo: {
                party_id: null,
                serial: null,
                group_name: null,
                product_store_id:null,
                roles:[]
            },
            defaultEditedItemEquipo: {
                party_id: null,
                serial: null,
                group_name: null,
                product_store_id:null,
                roles:[]
            },
            headers: [],
            dataTable: [],
            dataTableAsignacionRol: [],
            parentRoleType: [],
            productStrores: [],
            roleTypesId: [],
            workEffortTypes: [],
            products: [],            
            state: true,
            typeAction: "Nuevo",
            person: "",
            personId: null,
            workEfforttypeId: null,
            overlay: false,
            dialogTipoRol: false,
            dialogGestionRoles: false,
            dialogServicio: false,
            dialogEquipo:false,
            titleDataTable: "",
            search: null,
            action: "ROLE_TYPE",
        }),
        computed: {

            ...mapState("master", ["loadingTable", "tenantId", "paramAlertQuestion"]),
        },
        watch: {

            action: function(val) {

                this.dataTable = []

                switch (val) {
                    case "ROLE_TYPE":
                    this.headers = this.headersRoleType
                    this.titleDataTable = "TIPOS DE ROL"
                    this.getTipoRoles()
                    break
                    case "PARTY_ROLE":
                    this.headers = this.headersPartyRole
                    this.titleDataTable = "ASIGNACIÓN DE ROLES"
                    this.getEmployeeCrm()
                    break
                    case "SERVICE":
                    this.headers = this.headersService
                    this.titleDataTable = "GESTIÓN DE SERVICIOS"
                    this.getServiciosCrm()
                    break
                    case "PARTY_GROUP":
                    this.headers = this.headersPartyGroup
                    this.titleDataTable = "EQUIPOS"
                    this.getEquipos()
                    break
                }

            },

        },
        methods: {
            ...mapMutations("master", ["setUrl", "setLoadingTable", "setTitleToolbar"]),

            ...mapActions("master", ["requestApi", "alertNotification"]),

            getTipoRoles() {

                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "GET",
                    data: {
                    tenantId: this.tenantId,
                    typeList: "tipoRoles",
                    },
                })
                .then((res) => {
                    this.overlay = false

                    this.dataTable = res.data._embedded.configuracion_servicio
                    this.parentRoleType = res.data._embedded.configuracion_servicio

                })
                .catch(() => {
                    this.overlay = false
                })

            },

            getEmployeeCrm() {

                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "GET",
                    data: {
                        tenantId: this.tenantId,
                        typeList: "employeeCrm",
                    },
                })
                .then((res) => {
                    this.overlay = false

                    this.dataTable = res.data._embedded.configuracion_servicio
                })
                .catch(() => {
                    this.overlay = false
                })
            },

            getServiciosCrm() {
                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "GET",
                    data: {
                    tenantId: this.tenantId,
                    state: this.state,
                    typeList: "servicios",
                    },
                })
                .then((res) => {
                    this.overlay = false

                    this.dataTable = res.data._embedded.configuracion_servicio
                })
                .catch(() => {
                    this.overlay = false
                })
            },

            getEquipos() {

                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "GET",
                    data: {
                        tenantId: this.tenantId,
                        typeList: "equipos",
                    },
                })
                .then(res => {
                    console.log('res',res)
                    this.overlay = false
                    this.dataTable = res.data._embedded.configuracion_servicio
                    
                })
                .catch(() => {
                    this.overlay = false
                })

            },

            editTipoRol(item) {
                this.typeAction = "Editar"
                Object.assign(this.editedItemTipoRol, item)
                this.dialogAction()
            },

            storeTipoRol() {
                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "POST",
                    data: {
                    ...this.editedItemTipoRol,
                    tenantId: this.tenantId,
                    typeAction: "storeTipoRol",
                    },
                })
                .then((res) => {
                    this.getTipoRoles()
                    this.close()

                    this.alertNotification({
                        param: {
                        html: res.data.msg,
                        },
                    })

                    this.overlay = false
                })
                .catch(() => {
                    this.overlay = false
                })
            },

            dialogAction() {

                if (this.action == "ROLE_TYPE") {
                    this.openDialogTipoRol()
                } else if (this.action == "PARTY_ROLE") {
                    this.openDialogAsignacionRol()
                } else if (this.action == "SERVICE") {
                    this.openDialogServicio()
                }else if(this.action == "PARTY_GROUP"){
                    this.openDialogEquipo()
                }

            },

            editAction(item) {

                if (this.action == "ROLE_TYPE") {
                    this.editTipoRol(item)
                } else if (this.action == "PARTY_ROLE") {
                    this.editAsignacionRol(item)
                } else if (this.action == "SERVICE") {
                    this.editServicio(item)
                } else if (this.action == "PARTY_GROUP") {
                    this.editEquipo(item)
                }

            },

            openDialogTipoRol() {
                this.dialogTipoRol = true
            },

            openDialogAsignacionRol() {
                this.dialogGestionRoles = true
            },

            openDialogServicio() {
                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "GET",
                    data: {
                    tenantId: this.tenantId,
                    typeList: "partyRole",
                    },
                })
                .then((res) => {
                    this.overlay = false
                    this.roleTypesId = res.data._embedded.configuracion_servicio[1]
                    this.productStrores = res.data._embedded.configuracion_servicio[2]
                    this.workEffortTypes = res.data._embedded.configuracion_servicio[3]
                    this.products = res.data._embedded.configuracion_servicio[4]
                    this.dialogServicio = true
                })
                .catch(() => {
                    this.overlay = false
                })
            },

            openDialogEquipo() {

                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "GET",
                    data: {
                        tenantId: this.tenantId,
                        typeList: "rolesDispotivo",
                    },
                })
                .then(res => {
                    console.log(res)
                    this.overlay = false
                    this.roleTypesId = res.data._embedded.configuracion_servicio[0]
                    this.productStrores = res.data._embedded.configuracion_servicio[1]
                    this.dialogEquipo = true

                })
                .catch(() => {
                    this.overlay = false
                })
                

            },

            editAsignacionRol(item) {

                return new Promise((resolve, reject) => {
                    this.typeAction = "Editar"
                    this.setUrl("configuracion_servicio")
                    this.overlay = true
                    this.person = item.name
                    this.personId = item.party_id

                    this.requestApi({
                        method: "GET",
                        data: {
                            tenantId: this.tenantId,
                            partyId: item.party_id,
                            typeList: "partyRole",
                        },
                    })
                    .then((res) => {

                        this.overlay = false
                        
                        res.data._embedded.configuracion_servicio[0].map((obj) => {
                            obj.uuid = Math.random().toString(36).substr(0, 20)
                            return obj
                        })

                        this.dataTableAsignacionRol =
                        res.data._embedded.configuracion_servicio[0]
                        this.roleTypesId = res.data._embedded.configuracion_servicio[1]
                        this.workEfforttypes = res.data._embedded.configuracion_servicio[2]
                        this.dialogAction()
                        resolve()

                    })
                    .catch(() => {
                        this.overlay = false
                        reject()
                    })
                })

            },

            addTipoRol() {
                this.dataTableAsignacionRol.unshift({
                    uuid: Math.random()
                    .toString(36)
                    .substr(0, 20),
                    party_id: this.personId,
                    role_type_id: null,
                    description: textRol,
                })
            },

            storeAsginacionRol(props) {
                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "POST",
                    data: {
                        ...props.item,
                        tenantId: this.tenantId,
                        typeAction: "storeAsignacionRol",
                    },
                })
                .then(async (res) => {
                let news = this.dataTableAsignacionRol.filter(
                    (e) => e.description == textRol && e.role_type_id == null
                )

                await this.editAsignacionRol({
                    name: this.person,
                    party_id: props.item.party_id,
                })

                news.forEach((e) => {
                    this.dataTableAsignacionRol.unshift(e)
                })

                this.overlay = false
                this.alertNotification({
                    param: {
                    html: res.data.res.msg,
                    },
                })
                })
                .catch(() => {
                this.overlay = false
                })
            },

            deleteAsignacionRol(item) {
            if (item.role_type_id != null && item.description != textRol) {
                Vue.swal({
                    html: "Esta seguro de eliminar el rol a la persona?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cerrar",
                    ...this.paramAlertQuestion,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setUrl("configuracion_servicio")
                        this.overlay = true

                        this.requestApi({
                        method: "DELETE",
                        data: {
                            tenantId: this.tenantId,
                            roleTypeId: item.role_type_id,
                            partyId: item.party_id,
                            typeDelete: "partyRol",
                        },
                        })
                        .then((res) => {
                            console.log(res)

                            this.overlay = false
                            this.alertNotification({
                            param: {
                                html: res.data.detail,
                            },
                            })

                            let obj = this.dataTableAsignacionRol.find(
                            (e) => e.uuid == item.uuid
                            )
                            let index = this.dataTableAsignacionRol.indexOf(obj)
                            this.dataTableAsignacionRol.splice(index, 1)
                        })
                        .catch(() => {
                            this.overlay = false
                        })
                    }
                })
            } else {
                let obj = this.dataTableAsignacionRol.find((e) => e.uuid == item.uuid)
                let index = this.dataTableAsignacionRol.indexOf(obj)
                this.dataTableAsignacionRol.splice(index, 1)
            }
            },

            addRecursoServicio() {
                this.editedItemServicio.recursos.unshift({
                    uuid: Math.random()
                    .toString(36)
                    .substr(0, 20),
                    servicio_crm_id: null,
                    role_type_id: null,
                    description: textRol,
                })
            },

            asginacionRecurso(props) {
                let obj = this.editedItemServicio.recursos.find(
                    (e) => e.uuid == props.item.uuid
                )
                let index = this.editedItemServicio.recursos.indexOf(obj)

                let rt = this.roleTypesId.find(
                    (e) => e.role_type_id == props.item.role_type_id
                )

                this.editedItemServicio.recursos[index].description = rt.description
            },

            deleteRecursoServicio(item) {
                let obj = this.editedItemServicio.recursos.find(
                    (e) => e.uuid == item.uuid
                )
                let index = this.editedItemServicio.recursos.indexOf(obj)
                this.editedItemServicio.recursos.splice(index, 1)
            },

            storeServicio() {
                if (!this.$refs.form_servicio.validate()) return false

                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "POST",
                    data: {
                    ...this.editedItemServicio,
                    tenantId: this.tenantId,
                    typeAction: "storeServicio",
                    },
                })
                .then((res) => {
                    this.getServiciosCrm()
                    this.close()

                    this.alertNotification({
                        param: {
                        html: res.data.res.msg,
                        },
                    })

                    this.overlay = false
                })
                .catch(() => {
                    this.overlay = false
                })
            },

            editServicio(item) {
                this.setUrl("configuracion_servicio")
                this.overlay = true
                this.person = item.name
                this.personId = item.party_id

                this.requestApi({
                    method: "GET",
                    data: {
                    servicioCrmId: item.servicio_crm_id,
                    tenantId: this.tenantId,
                    partyId: item.party_id,
                    typeList: "servicio",
                    },
                })
                .then((res) => {
                this.overlay = false

                res.data._embedded.configuracion_servicio[0].recursos.map((obj) => {
                    obj.uuid = Math.random().toString(36).substr(0, 20)
                    return obj
                })

                res.data._embedded.configuracion_servicio[0].sub_servicio.map((obj) => {
                    obj.uuid = Math.random().toString(36).substr(0, 20)
                    return obj
                })
                
                Object.assign(
                    this.editedItemServicio,
                    res.data._embedded.configuracion_servicio[0]
                )
                console.log(
                    "res.data._embedded.configuracion_servicio",
                    res.data._embedded.configuracion_servicio
                )
                this.dialogAction()
                })
                .catch(() => {
                this.overlay = false
                })
            },

            deleteServicio(item) {
                Vue.swal({
                    html: "Esta seguro de cancelar el servicio?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cerrar",
                    ...this.paramAlertQuestion,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setUrl("configuracion_servicio")
                        this.overlay = true

                        this.requestApi({
                            method: "DELETE",
                            data: {
                            servicioCrmId: item.servicio_crm_id,
                            tenantId: this.tenantId,
                            typeDelete: "servicio",
                            },
                        })
                        .then((res) => {
                        this.editedItemServicio = []
                        this.editedItemServicio.sub_servicio = []
                        this.getServiciosCrm()

                        this.alertNotification({
                            param: {
                            html: res.data.res.msg,
                            },
                        })

                        this.overlay = false
                        })
                        .catch(() => {
                        this.overlay = false
                        })
                    }
                })
            },

            activarServicio(item) {
                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "POST",
                    data: {
                    servicioCrmId: item.servicio_crm_id,
                    tenantId: this.tenantId,
                    typeAction: "activaServicio",
                    },
                })
                .then((res) => {
                    this.getServiciosCrm()

                    this.alertNotification({
                        param: {
                        html: res.data.res.msg,
                        },
                    })

                    this.overlay = false
                })
                .catch(() => {
                    this.overlay = false
                })
            },

            addSubServicio() {
                this.editedItemServicio.sub_servicio.unshift({
                    uuid: Math.random().toString(36).substr(0, 20),
                    servicio_crm_id: null,
                    product_id: null,
                    internal_name: textSubServicio,
                    valoracion: false,
                    tiempo: 0,
                })
            },

            deleteSubServicio(item) {
                let obj = this.editedItemServicio.sub_servicio.find(
                    (e) => e.uuid == item.uuid
                )

                let index = this.editedItemServicio.sub_servicio.indexOf(obj)
                this.editedItemServicio.sub_servicio.splice(index, 1)
            },

            asginacionSubServicio(props) {
                let obj = this.editedItemServicio.sub_servicio.find(
                    (e) => e.uuid == props.item.uuid
                )

                let index = this.editedItemServicio.sub_servicio.indexOf(obj)

                let p = this.products.find((e) => e.product_id == props.item.product_id)

                this.editedItemServicio.sub_servicio[index].product_id = p.product_id

                this.editedItemServicio.sub_servicio[index].internal_name = p.internal_name
            },

            close() {

                Object.assign(this.editedItemTipoRol, this.defaultEditedItemTipoRol)
                Object.assign(this.editedItemServicio, this.defaultEditedItemServicio)
                Object.assign(this.editedItemEquipo, this.defaultEditedItemEquipo)

                this.dialogTipoRol = false
                this.dialogGestionRoles = false
                this.dialogServicio = false
                this.dialogEquipo =false
            },

            borrarSubServicio(item) {
                console.log(item.sub_servicio_crm_id)
                Vue.swal({
                    html: "Esta seguro de borrar el sub servicio ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cerrar",
                    ...this.paramAlertQuestion
                }).then((result) => {

                    if (result.isConfirmed) {

                        this.setUrl("configuracion_servicio")
                        this.overlay = true

                        this.requestApi({
                            method: "POST",
                            data: {
                                sub_servicio_crm_id: item.sub_servicio_crm_id,
                                tenantId: this.tenantId,
                                typeAction: "borrarSubServicio",
                            },
                        }).then((res) => {
                            //this.getTipoRoles()
                            this.getServiciosCrm()

                            this.alertNotification({
                                param: {
                                html: res.data.res.msg,
                                },
                            })

                            
                            this.editedItemServicio.sub_servicio = this.editedItemServicio.sub_servicio.filter(e => e.uuid != item.uuid)

                            this.overlay = false

                        }).catch(() => {
                            this.overlay = false
                        })

                    }

                })

            },

            storeEquipo(){

                if(!this.$refs.form_equipo.validate())
                    return false

                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "POST",
                    data: {
                        partyId: this.editedItemEquipo.party_id,
                        group_name: this.editedItemEquipo.group_name,
                        roles: this.editedItemEquipo.roles,
                        product_store_id: this.editedItemEquipo.product_store_id,
                        tenantId: this.tenantId,
                        typeAction: "storeEquipo",
                    },
                })
                .then(res => {

                    this.getEquipos()

                    this.alertNotification({
                        param: {
                            html: res.data.res.msg
                        }
                    })

                    this.dialogEquipo =false
                    this.overlay = false
                    
                })
                .catch(() => {

                    this.overlay = false

                })

            },

            editEquipo(item) {
                console.log('item',item)
                this.typeAction = "Editar"
                Object.assign(this.editedItemEquipo, item)
                this.dialogAction()

            },

            updateEstadoEquipo(item){

                this.setUrl("configuracion_servicio")
                this.overlay = true

                this.requestApi({
                    method: "POST",
                    data: {
                        partyId: item.party_id,                        
                        tenantId: this.tenantId,
                        typeAction: "updateEstadoEquipo",
                    },
                })
                .then(res => {

                    this.getEquipos()

                    this.alertNotification({
                        param: {
                            html: res.data.res.msg
                        }
                    })

                    this.overlay = false
                    
                })
                .catch(() => {

                    this.overlay = false

                })

            },

            addRolesEquipo(){

                this.editedItemEquipo.roles.unshift({
                    uuid: Math.random().toString(36).substr(0, 20),
                    role_type_id: null,
                    description: textRol,
                })

            },

            asginacionRolEquipo(props) {

                let obj = this.editedItemEquipo.roles.find((e) => e.uuid == props.item.uuid)

                let index = this.editedItemEquipo.roles.indexOf(obj)

                let rt = this.roleTypesId.find((e) => e.role_type_id == props.item.role_type_id)

                this.editedItemEquipo.roles[index].description = rt.description

            },
            
            deleteRolEquipo(item) {

                let obj = this.editedItemEquipo.roles.find((e) => e.uuid == item.uuid)

                let index = this.editedItemEquipo.roles.indexOf(obj)

                this.editedItemEquipo.roles.splice(index, 1)
            },

        },
        mounted() {

            this.setTitleToolbar("CONFIGURACIONES DE SERVICIO")

        },
        created() {
            this.headers = this.headersRoleType
            this.titleDataTable = "TIPOS DE ROL"
            this.getTipoRoles()
        },
    }
</script>

<style>

    .check-dias {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between
    }

    .v-input__slot{
        justify-content: center
    }
    
</style>
